import React , {useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Container, Card, Form, Button, ListGroup, OverlayTrigger, Popover, Tooltip, Carousel, InputGroup, FormControl, Table, Badge, Dropdown } from 'react-bootstrap'
import Message from '../components/Message'
import Loader from '../components/Loader'


import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile,
    deviceDetect
  } from "react-device-detect";


  import CreatorSessionCard from '../components/creator/CreatorSessionCard'



export const CreatorSessionsScreen = ({match, history}) => {


  
    const dispatch = useDispatch()

  
    const [nDays, setNDays] = useState('14')
    const [lessonType, setLessonType] = useState('quiz')

    const selectCourseRequest = () => {

    }

    const selectSessionRequest = () => {

    }

    return (

        <>

              <Card>

                    <Card.Header>

                        <p className='h3' style={{textAlign:'center'}}>Learning Sessions to give students a guided learning experience.</p>

                    </Card.Header>

              </Card>


                <InputGroup>
                <Form.Control as='select' value={nDays} onChange={(e) => setNDays(e.target.value)} style={{margin:'5px'}}> 
                <option>n Weeks</option>
                <option value="14">14 days</option>
                <option value="30">30 days</option>
                <option value="60">60 days</option>
                <option value="90">90 days</option>
                </Form.Control>

                <Button onClick={()=>selectCourseRequest()}>Select</Button>
                
                </InputGroup>

                <hr />
                <br />


                <ListGroup>
                    <ListGroup.Item>
                        Sessions
                    </ListGroup.Item>
                </ListGroup>


                <Table>
                    <tr>
                        <td className='h5'>Day</td>
                        <td className='h5'>Session</td>
                    </tr>
                    {Array(parseInt(nDays)).fill(0).map((val, index) =>(
                        <tr style={{padding:'10px'}}>
                            <td>
                                {index+1}
                            </td>
                            <td>

                                <CreatorSessionCard index={index}/>


                                {/* <Card style={{border:'None'}}>
                                <Card.Header>
                                Session {index+1}
                                </Card.Header>

                                <Card.Body>
                                    <InputGroup>
                                    <Form.Control as='select' value={lessonType} onChange={(e) => setLessonType(e.target.value)} style={{margin:'5px'}}> 
                                        <option>Session Type</option>
                                        <option value="video">Video</option>
                                        <option value="reading">Reading</option>
                                        <option value="quiz">Quiz</option>
                                        <option value="paper">Paper</option>
                                    </Form.Control>
                                    <Button>Add Lesson</Button>
                                    </InputGroup>
                                        

                                </Card.Body>
                                
                                </Card> */}
                               
                            </td>
                        </tr>
                    ))}
                </Table>




        </>
    )
}


export default CreatorSessionsScreen



