import React , {useState, useEffect, Fragment } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Container, Card, Form, Button, ListGroup, OverlayTrigger, Popover, Tooltip, Carousel, InputGroup, FormControl, Table, Radio, Badge } from 'react-bootstrap'

import PassageParaEdit from './PassageParaEdit'


import {updatePassage} from '../../actions/creatorActions'

export const CreatorSessionCard = ({ index }) => {


  const dispatch = useDispatch()

  
  const [nDays, setNDays] = useState('14')
  const [lessonType, setLessonType] = useState('quiz')

  const addSessionLesson = () => {
    
  }


  useEffect(() => {

      

  }, []);



  
  return (

    <>
    
            <Card style={{border:'None'}}>
                <Card.Header>
                Session {index+1}
                </Card.Header>

                <Card.Body>
                    <InputGroup>
                    <Form.Control as='select' value={lessonType} onChange={(e) => setLessonType(e.target.value)} style={{margin:'5px'}}> 
                        <option>Session Type</option>
                        <option value="video">Video</option>
                        <option value="reading">Reading</option>
                        <option value="quiz">Quiz</option>
                        <option value="paper">Paper</option>
                    </Form.Control>
                    <Button>Add Lesson</Button>
                    </InputGroup>
                        

                </Card.Body>
                
            </Card>


    
    </>

  );
};

export default CreatorSessionCard;
