import React , {useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Container, Card, Form, Button, ListGroup, OverlayTrigger, Popover, Tooltip, InputGroup, FormControl, Table } from 'react-bootstrap'


import Carousel from 'react-bootstrap/Carousel';
import VideoComponent from '../components/VideoComponent'

import LandingDesktop from '../components/landing/LandingDesktop'
import LandingMobile from '../components/landing/LandingMobile'

import Meta from '../components/Meta'


import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile,
    deviceDetect
  } from "react-device-detect";

export const LandingScreen = ({match, history}) => {

    
    const dispatch = useDispatch()



    useEffect(() => {


    }, [])




    return(

        <>
        
        <Meta title={"Qwz4u: Engish Quizzes for Exams and Fun"} description={"Qwz4u provides quizzes, tests and materials to learn for exams"} />


        {isMobile ? (
            
            <LandingMobile />
        ): (
            <LandingDesktop />
        )}






        </>

 



    )


}

export default LandingScreen


