import axios from 'axios';
// import {setAlert} from './alertActions';
import {

    CREATE_LOAD_QUIZLIST,
    CREATE_LOAD_QUIZLIST_ERROR,
    
    CREATE_LOAD_QUIZ_BY_ID,
    CREATE_LOAD_QUIZ_ERROR,
    
    CREATE_ADD_QUIZ,

    CREATE_ADD_QUIZ_QUESTION,
    EDIT_QUIZ_QUESTION,
    DELETE_QUIZ_QUESTION,

    EDIT_QUIZ_CONCEPT,
    EDIT_QUIZ_INSTRUCTIONS,
    EDIT_QUIZ_PASSAGE
    

} from '../constants/creatorConstants'








export const loadCreatorQuizList = () => async (dispatch, getState) => {

    try{

        const { userR: { user }} = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${user.token}`
            }
        }


        const full_url = `/api/creator`

        const { data } = await axios.get(full_url,config)

        console.log('quiz : ',data)


        dispatch({
            type: CREATE_LOAD_QUIZLIST,
            payload: data
        })

    
    }catch (error){

        dispatch({
            type: CREATE_LOAD_QUIZLIST_ERROR,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,

        })

    }

}



export const loadCreatorSectionQuizList = (section_id) => async (dispatch, getState) => {

    try{

        const { userR: { user }} = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${user.token}`
            }
        }


        const full_url = `/api/creator`

        const { data } = await axios.put(full_url,{section_id},config)

        console.log('quiz : ',data)


        dispatch({
            type: CREATE_LOAD_QUIZLIST,
            payload: data
        })

    
    }catch (error){

        dispatch({
            type: CREATE_LOAD_QUIZLIST_ERROR,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,

        })

    }

}



export const loadCreatorQuizById = (quiz_id) => async (dispatch, getState) => {

    try{

        const { userR: { user }} = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${user.token}`
            }
        }

        console.log("Quiz ID ", quiz_id)

        const full_url = `/api/creator/${quiz_id}`

        const { data } = await axios.get(full_url,config)

        console.log('quiz : ',data)


        dispatch({
            type: CREATE_LOAD_QUIZ_BY_ID,
            payload: data
        })

    
    }catch (error){

        dispatch({
            type: CREATE_LOAD_QUIZ_ERROR,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,

        })

    }

}




export const addCreatorQuiz = (title, category) => async (dispatch, getState) => {

    try{

        const { userR: { user }} = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${user.token}`
            }
        }


        const full_url = `/api/creator`

        const { data } = await axios.post(full_url, {title, category}, config)

        console.log('add quiz : ',data)


        dispatch({
            type: CREATE_ADD_QUIZ,
            payload: data
        })

    
    }catch (error){

        dispatch({
            type: CREATE_LOAD_QUIZLIST_ERROR,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,

        })

    }

}





export const addCreatorQuestionQuiz = (quiz_id, questionType, instruction, question, options, answer, answers) => async (dispatch, getState) => {

    try{

        const { userR: { user }} = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${user.token}`
            }
        }


        const full_url = `/api/creator/question/${quiz_id}`

        const { data } = await axios.post(full_url, {questionType, instruction, question, options, answer, answers}, config)

        console.log('question : ',data)


        dispatch({
            type: CREATE_ADD_QUIZ_QUESTION,
            payload: data
        })

    
    }catch (error){

        dispatch({
            type: CREATE_LOAD_QUIZ_ERROR,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,

        })

    }

}





export const updateCreatorQuestion = (quiz_id, question_id, instruction, question, options, answer) => async (dispatch, getState) => {

    try{

        const { userR: { user }} = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${user.token}`
            }
        }

        console.log("Updte Questions ", quiz_id, question_id)


        const full_url = `/api/creator/question/${quiz_id}/${question_id}`

        const { data } = await axios.put(full_url, {instruction, question, options, answer}, config)

        console.log('question : ',data)


        dispatch({
            type:  EDIT_QUIZ_QUESTION,
            payload: data
        })

    
    }catch (error){

        dispatch({
            type: CREATE_LOAD_QUIZ_ERROR,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,

        })

    }

}




export const deleteCreatorQuestion = (quiz_id, question_id) => async (dispatch, getState) => {

    try{

        const { userR: { user }} = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${user.token}`
            }
        }


        const full_url = `/api/creator/question/${quiz_id}/${question_id}`

        const { data } = await axios.delete(full_url, config)

        console.log('question : ',data)


        dispatch({
            type: DELETE_QUIZ_QUESTION,
            payload: data
        })

    
    }catch (error){

        dispatch({
            type: CREATE_LOAD_QUIZ_ERROR,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,

        })

    }

}






export const updateConcept = (quiz_id, item_id, item_text) => async (dispatch, getState) => {

    try{

        const { userR: { user }} = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${user.token}`
            }
        }


        const full_url = `/api/creator/concept/${quiz_id}`

        const { data } = await axios.put(full_url, {item_id, item_text},config)

        console.log('question : ',data)


        dispatch({
            type: EDIT_QUIZ_CONCEPT,
            payload: data
        })

    
    }catch (error){

        dispatch({
            type: CREATE_LOAD_QUIZ_ERROR,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,

        })

    }

}






export const postQuiz = (quiz_id) => async (dispatch, getState) => {

    try{

        const { userR: { user }} = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${user.token}`
            }
        }


        const full_url = `/api/creator/post/${quiz_id}`

        console.log("Full Url ", full_url)

        const { data } = await axios.post(full_url,{},config)

        console.log('Quiz in : ',data)

        // dispatch({
        //     type: LOAD_QUIZ_BY_ID,
        //     payload: data
        // })
       
    
    }catch (error){

        dispatch({
            type: CREATE_LOAD_QUIZ_ERROR,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,

        })

    }

}


export const unpostQuiz = (quiz_id) => async (dispatch, getState) => {

    try{

        const { userR: { user }} = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${user.token}`
            }
        }


        const full_url = `/api/creator/unpost/${quiz_id}`

        console.log("Full Url ", full_url)

        const { data } = await axios.post(full_url,{},config)

        console.log('Quiz in : ',data)

        // dispatch({
        //     type: LOAD_QUIZ_BY_ID,
        //     payload: data
        // })
       
    
    }catch (error){

        dispatch({
            type: CREATE_LOAD_QUIZ_ERROR,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,

        })

    }

}





export const updateInstructions = (quiz_id, instruction) => async (dispatch, getState) => {

    try{

        const { userR: { user }} = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${user.token}`
            }
        }


        const full_url = `/api/creator/instructions/${quiz_id}`

        console.log("Full Url ", full_url)

        const { data } = await axios.put(full_url,{instruction},config)

        console.log('Quiz in : ',data)

        dispatch({
            type: EDIT_QUIZ_INSTRUCTIONS,
            payload: data
        })
       
    
    }catch (error){

        dispatch({
            type: CREATE_LOAD_QUIZ_ERROR,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,

        })

    }

}







export const updatePassage = (quiz_id, mode, title, index, text) => async (dispatch, getState) => {

    try{

        const { userR: { user }} = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${user.token}`
            }
        }


        const full_url = `/api/creator/passage/${quiz_id}`

        console.log("Full Url ", full_url)

        const { data } = await axios.put(full_url,{mode, title, index, text},config)

        console.log('Quiz in : ',data)

        dispatch({
            type: EDIT_QUIZ_PASSAGE,
            payload: data
        })
       
    
    }catch (error){

        dispatch({
            type: CREATE_LOAD_QUIZ_ERROR,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,

        })

    }

}
