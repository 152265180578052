import React , {useState, useEffect, Fragment } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Container, Card, Form, Button, ListGroup, OverlayTrigger, Popover, Tooltip, Carousel, InputGroup, FormControl, Table, Radio, Badge } from 'react-bootstrap'


import {addCreatorQuestionQuiz} from '../../actions/creatorActions'



export const CreatorQuestionCard = ({ quiz_id}) => {


  const dispatch = useDispatch()

  const [instructions, setInstructions] = useState('')
    
  const [qtext, setQText] = useState('')

  const [questionType, setQuestionType] = useState('mcq')

  const [nOptions, setnOptions] = useState(4)

  const [hiddenUpdate, setHiddenUpdate] = useState(0)
    
  //const [options, setOptions] = useState([])

  const [option1, setOption1] = useState("")
  const [option2, setOption2] = useState("")
  const [option3, setOption3] = useState("")
  const [option4, setOption4] = useState("")
  const [option5, setOption5] = useState("")
  const [option6, setOption6] = useState("")



  const [optionin, setOptionin] = useState('')

  const [answer, setAnswer] = useState(-1)

  const [answers, setAnswers] = useState([])
  const updateAnswers = (selected_in) => {
    var answersl = answers

    if(answersl.includes(selected_in)){
      answersl = answersl.filter(canswer => canswer !=selected_in)
    }else{
      answersl.push(selected_in)
    }

    setAnswers(answersl)


    setHiddenUpdate(hiddenUpdate + 1)


  }

  const [learn, setLearn] = useState([])


  const addOptionsItem = () => {

    var nOption = {
        'type':'text',
        'text':optionin
    }

    //setOptions(options =>  [...options, nOption])

  }


  const setAnswerRequest = (e, soption, index) => {

    switch (e.detail) {
        case 1: {
          console.log('single click');
          

          break;
        }
        case 2: {
          console.log('double click');
            setAnswer(index)
          break;
        }
        case 3: {
          console.log('triple click');
          break;
        }
        default: {
          break;
        }
      }



  }

  

  const addLearnItem = () => {

  }




  useEffect(() => {



  }, []);


  const submitQuestionRequest = () => {
    
      var options = [option1, option2, option3, option4, option5, option6]

      var options_trim = []
      for(var iV=0;iV<nOptions; iV++){
        options_trim.push(options[iV])
      }


      dispatch(addCreatorQuestionQuiz(quiz_id, questionType, instructions, qtext, options_trim, answer, answers))
    

      setInstructions('')
      setQText('')
      setOption1('')
      setOption2('')
      setOption3('')
      setOption4('')
      setOption5('')
      setOption6('')


   }



   const currentQuestion = {
    questionNo:0,
    submit:'',
    solution:'',
    learn:''
    }

  
  return (

    <>
    
    <Card style={{padding:'20px', borderRadius:'20px', backgroundColor:'#f5f295', marginBottom:'20px'}}>


        <>

        <p><span>Create Question</span><span style={{color:'#f5f295'}}>{hiddenUpdate}</span></p> 


        <Form.Control type='text' placeholder='Instruction' value={instructions} onChange={(e) => setInstructions(e.target.value)}> 
        </Form.Control>


        <Card style={{margin:'10px', borderRadius:'5px'}}>
            <Card.Header style={{borderRadius:'5px'}}>

                <Form.Control type='text' placeholder='Question' value={qtext} onChange={(e) => setQText(e.target.value)}> 
                </Form.Control>


            </Card.Header>

        </Card>

        <Row>
          <Col>
          <Form.Control as='select' value={questionType} onChange={(e) => setQuestionType(e.target.value)} style={{margin:'5px'}}> 
          <option>Question Types</option>
          <option value="mcq">MCQ</option>
          <option value="multiple">Multiple</option>
          </Form.Control>
          </Col>
          <Col>
          <Form.Control as='select' value={nOptions} onChange={(e) => setnOptions(e.target.value)} style={{margin:'5px'}}> 
          <option>N Options</option>
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
          <option value="4">4</option>
          <option value="5">5</option>
          <option value="6">6</option>
        </Form.Control>
          </Col>
        </Row>



        <Row>
          <Col>
          <InputGroup>
        <Form.Control type='text' placeholder='Option' value={option1} onChange={(e) => setOption1(e.target.value)} style={{margin:'5px'}}> 
        </Form.Control>

        </InputGroup>
          </Col>
          <Col>
          <InputGroup>
        <Form.Control type='text' placeholder='Option' value={option2} onChange={(e) => setOption2(e.target.value)} style={{margin:'5px'}}> 
        </Form.Control>

        </InputGroup>
          </Col>
        </Row>


        <Row>
          <Col>
          
          <InputGroup>
        <Form.Control type='text' placeholder='Option' value={option3} onChange={(e) => setOption3(e.target.value)} style={{margin:'5px'}}> 
        </Form.Control>
        </InputGroup>
          </Col>
          <Col>
          <InputGroup>
        <Form.Control type='text' placeholder='Option' value={option4} onChange={(e) => setOption4(e.target.value)} style={{margin:'5px'}}> 
        </Form.Control>

        </InputGroup>
          </Col>
        </Row>

        <Row>
          <Col>
          
        <InputGroup>
        <Form.Control type='text' placeholder='Option' value={option5} onChange={(e) => setOption5(e.target.value)} style={{margin:'5px'}}> 
        </Form.Control>

        </InputGroup>
          </Col>
          <Col>
          <InputGroup>
        <Form.Control type='text' placeholder='Option' value={option6} onChange={(e) => setOption6(e.target.value)} style={{margin:'5px'}}> 
        </Form.Control>

        </InputGroup>
          </Col>
        </Row>


        {questionType == 'mcq' && (
          <>
        <Form.Control as='select' value={answer} onChange={(e) => setAnswer(e.target.value)} style={{margin:'5px'}}> 
          <option>Select the Answer</option>
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
          <option value="4">4</option>
          <option value="5">5</option>
          <option value="6">6</option>
        </Form.Control>
          </>
        )}
        
        {questionType == 'multiple' && (
          <>

            <p>Selected Answers : {answers.toString()}</p>

            <InputGroup>
              <Button onClick={()=>updateAnswers(1)} style={{backgroundColor: answers.includes(1) ? '#d4c068' : 'white', color:'black', margin:'3px', borderRadius:'10px'}}>1</Button>
              <Button onClick={()=>updateAnswers(2)} style={{backgroundColor: answers.includes(2) ? '#d4c068' : 'white', color:'black', margin:'3px', borderRadius:'10px'}}>2</Button>
              <Button onClick={()=>updateAnswers(3)} style={{backgroundColor: answers.includes(3) ? '#d4c068' : 'white', color:'black', margin:'3px', borderRadius:'10px'}}>3</Button>
              <Button onClick={()=>updateAnswers(4)} style={{backgroundColor: answers.includes(4) ? '#d4c068' : 'white', color:'black', margin:'3px', borderRadius:'10px'}}>4</Button>
              <Button onClick={()=>updateAnswers(5)} style={{backgroundColor: answers.includes(5) ? '#d4c068' : 'white', color:'black', margin:'3px', borderRadius:'10px'}}>5</Button>
              <Button onClick={()=>updateAnswers(6)} style={{backgroundColor: answers.includes(6) ? '#d4c068' : 'white', color:'black', margin:'3px', borderRadius:'10px'}}>6</Button>
            </InputGroup>
            
          </>
        )}
        




        {/* {options && options.map((coption, index)=>(

            <Card style={{margin:'10px', borderRadius:'20px', backgroundColor:answer==index?'#b0ed6f':'white'}} onClick={(e)=>setAnswerRequest(e, coption, index)}>
                <Card.Header style={{borderRadius:'20px'}}>


                    <p className='h4'>{coption.text}</p>


                </Card.Header>

            </Card>

        ))} */}




    
    </>


    <div>
    <Button onClick={()=>submitQuestionRequest()} style={{width:'25vw'}}>
        Submit
    </Button>
    </div>

</Card>



{/* <Card style={{margin:'10px', borderRadius:'20px'}}>
    <Card.Header style={{borderRadius:'20px'}}>
    <p className='h4'>Learn</p>

    
        
    </Card.Header>
    
</Card> */}
    
    </>

  );
};

export default CreatorQuestionCard;
