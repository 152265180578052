import React , {useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Container, Card, Form, Button, ListGroup, OverlayTrigger, Popover, Tooltip, Carousel, InputGroup, FormControl, Table, Badge, Dropdown } from 'react-bootstrap'
import Message from '../components/Message'
import Loader from '../components/Loader'
import Meta from '../components/Meta'
import FormContainer from '../components/FormContainer'
import QuestionCard from '../components/quiz/QuestionCard'
import QuizCompleted from '../components/quiz/QuizCompleted'
import ConceptCard from '../components/quiz/ConceptCard'


import {loadQuizList, loadQuizById, loadQuizByURL, loadQuizListBySection} from '../actions/quizActions'

import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile,
    deviceDetect
  } from "react-device-detect";



export const QuizDetailScreen = ({match, history}) => {


    const quiz_id = match.params.quiz_id

    const dispatch = useDispatch()

    const [selectedQuiz, setSelectedQuiz] = useState('')
    const [selectedSection, setSelectedSection] = useState('vs')

    const QuizList = useSelector(state => state.QuizList)
    const { topics } = QuizList


    const QuizDetail = useSelector(state => state.QuizDetail)
    const { title, questions, attempted, score, concept, pageSEO, nextQuiz, passage, instructions, instruction } = QuizDetail


    const [cQ, setCQ] = useState(0)

    const [viewConcept, setViewConcept] = useState(false)
    const toggleConcept = () => {
        setViewConcept(viewConcept => viewConcept = !viewConcept)
    }

    


    const selectQuizURL = (quiz_url) => {
        dispatch(loadQuizByURL(quiz_url))
    }

    const loadQuizzesByTopicRequest = (section_in) =>{
        if(section_in !=  selectedSection){
            setSelectedSection(section_in)
            dispatch(loadQuizListBySection(section_in))
        }
        
    }


    useEffect(() => {

        //dispatch(loadQuizList())

        dispatch(loadQuizListBySection(selectedSection))

        if(quiz_id){
            selectQuizURL(quiz_id)
        }

    }, [quiz_id])


    const selectQuizTopicRequest = (stopic) => {

        dispatch(loadQuizById(stopic._id))

    }




    const updateCurrentQuestionRequest = (mode) => {

        if(questions){

            if(mode == 'next'){
                if(cQ < questions.length-1){
                    setCQ(cQ+1)
                }
            }
    
            if(mode == 'prev'){
                if(cQ > 0){
                    setCQ(cQ-1)
                }
            }


        }



    }



    return (

        <>

                {(pageSEO && pageSEO != null) ? (
                <Meta title={pageSEO.title} description={pageSEO.description} keywords={pageSEO.keywords}/>

                ):
                (
                    <Meta title={'Welcome To Qwz4U, your favourite English Quiz App'} description={'Master English with Qwz4u, the leading English quiz app offering comprehensive grammar, vocabulary, and reading comprehension quizzes. Designed for learners at all levels, our app adapts to your pace, providing instant feedback, detailed explanations, and progress tracking to enhance your language skills effectively. Join our community for daily challenges and improve your English fluency today!'} keywords={'Qwz4u, English quiz app, learn English, grammar quizzes, vocabulary builder, reading comprehension, language learning, improve English skills, educational app, English practice, language mastery'}/>

                )
                }


                <Row>


                    <Col style={{minHeight:'98vh', maxHeight:'98vh', overflow:'scroll'}}>


                        
                {!questions || questions.length == 0 && (
                    <Card>

                     <p className='h1' style={{textAlign:'center'}}>Welcome to Qwz4U : Your favourite English Quiz App</p>

                        <p className='h1' style={{textAlign:'center'}}>Start a Quiz by picking a topic from the left</p>
                    </Card>
                )}


                    {questions && questions.length > 0 && (


                        <Card style={{padding:'20px', margin:'20px', position: 'sticky', top: '0px', zIndex: '100', border:'solid'}}>

                            {title && (
                                <>
                                <span className='h2'><span>Quiz : {title}</span> <Badge style={{ backgroundColor:'#bb5ced', borderRadius:'10px', color:'white'}} onClick={()=>toggleConcept()}>Concept</Badge> </span>
                                <p className='h4'><span>Attempted : {attempted}</span> | <span> Score : {score}</span></p>
                                </>
                            

                            )}


                        </Card>

                    )}


                        {concept && concept != null && viewConcept && (
                            <ConceptCard concept={concept}/>
                        )}

                        {/* {instructions && instructions.map((cinstr, index)=>(
                            <p className='h5'>{cinstr}</p>
                        ))} */}

                        {instruction && (
                            <p className='h3' style={{textAlign:'center'}}>{instruction}</p>
                        )}


                        {passage && passage.title && (
                            <Card style={{border:'None'}}>
                                <Card.Header>
                                    <p className='h4'>{passage.title}</p>
                                </Card.Header>
                                <Card.Body>
                                    {passage.paras && passage.paras.map((cpara, index)=>(
                                        <p className='h5'>
                                            {cpara}
                                        </p>
                                    ))}
                                </Card.Body>
                            </Card>
                        )}



                        {questions && questions.map((cquestion, index)=>(
                            <>
                                <QuestionCard selectedQuestion={cquestion}/>
                            </>
                        ))}

 
                        {(attempted && attempted > 0 && attempted == questions.length) ? (
                            <>
                                <QuizCompleted attempted={attempted} score={score} nquestions={questions.length} nextQuiz={nextQuiz}/>
                            
                                
                            </>


                        ): (
                            <></>
                        )}
  
                        
                    
                    </Col>
                </Row>



        </>
    )
}


export default QuizDetailScreen



