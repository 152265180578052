import React , {useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Container, Card, Form, Button, ListGroup, OverlayTrigger, Popover, Tooltip, Carousel, InputGroup, FormControl, Table, Badge } from 'react-bootstrap'
import Message from '../components/Message'
import Loader from '../components/Loader'
import Meta from '../components/Meta'
import FormContainer from '../components/FormContainer'
import QuestionCard from '../components/quiz/QuestionCard'

import CreatorQuestionCard from '../components/creator/CreatorQuestionCard'
import QuestionEditCard from '../components/creator/QuestionEditCard'
import ConceptCardEdit from '../components/quiz/ConceptCardEdit'
import CreatorInstructionsCard from '../components/creator/CreatorInstructionsCard'
import CreatorPassageCard from '../components/creator/CreatorPassageCard'

import {loadCreatorQuizList, loadCreatorQuizById, addCreatorQuiz, loadCreatorSectionQuizList, postQuiz, unpostQuiz} from '../actions/creatorActions'



export const CreateQuizScreen = ({match, history}) => {


    const [cQ, setCQ] = useState(0)
    const [qtitle, setQTitle] = useState('')
    const [quizCategory, setQuizCategory] = useState('')

    const [selectedSection, setSelectedSection] = useState('vs')

    const [updateQuizMode, setUpdateQuizMode] = useState('')

    const [viewConcept, setViewConcept] = useState(false)
    const toggleConcept = () => {
        setViewConcept(viewConcept => viewConcept = !viewConcept)
    }

    const dispatch = useDispatch()


    const userR = useSelector(state => state.userR)
    const { user } = userR

    const CreateQuizList = useSelector(state => state.CreateQuizList)
    const { quizlist } = CreateQuizList


    const CreateQuizDetail = useSelector(state => state.CreateQuizDetail)
    const { quiz_id, title, questions, attempted, score, concept, pageSEO, nextQuiz,passage, instructions, instruction } = CreateQuizDetail



    const selectQuizRequest = (quiz_id) => {
        dispatch(loadCreatorQuizById(quiz_id))
    }


    const createQuizRequest = () => {
        dispatch(addCreatorQuiz(qtitle, selectedSection))
    }


    


    const loadQuizzesByTopicRequest = (section_in) =>{
        if(section_in !=  selectedSection){
            setSelectedSection(section_in)
            dispatch(loadCreatorSectionQuizList(section_in))
            //dispatch(loadQuizListBySection(section_in))
        }
        
    }

    const postQuizRequest = () => {
        dispatch(postQuiz(quiz_id))
    }
    
    const unpostQuizRequest = () => {
        dispatch(unpostQuiz(quiz_id))
    }

    useEffect(() => {

       //dispatch(loadCreatorQuizList())
       dispatch(loadCreatorSectionQuizList(selectedSection))

    }, [])



    return (

        <>
 
                

                <InputGroup style={{marginTop:'1px', marginBottom:'1px'}}>
                    <p className='h3' style={{marginRight:'10px'}}>Create Quiz</p>
                    <Button style={{paddingTop:'2px', paddingBottom:'2px', backgroundColor:'#bb5ced', marginRight:'1px'}} onClick={()=>loadQuizzesByTopicRequest('vs')}>vs Quizzes</Button>
                    <Button style={{paddingTop:'2px', paddingBottom:'2px', backgroundColor:'#bb5ced', marginRight:'1px'}} onClick={()=>loadQuizzesByTopicRequest('vocabulary')}>Vocabulary</Button>
                    <Button style={{paddingTop:'2px', paddingBottom:'2px', backgroundColor:'#bb5ced', marginRight:'1px'}} onClick={()=>loadQuizzesByTopicRequest('grammar')}>Grammar</Button>
                    {/* <Button style={{paddingTop:'2px', paddingBottom:'2px', backgroundColor:'#bb5ced', marginRight:'1px'}} onClick={()=>loadQuizzesByTopicRequest('advanced grammar')}>Advanced Grammar</Button> */}
                    <Button style={{paddingTop:'2px', paddingBottom:'2px', backgroundColor:'#bb5ced', marginRight:'1px'}} onClick={()=>loadQuizzesByTopicRequest('comprehension')}>Comprehension</Button>
                    <Button style={{paddingTop:'2px', paddingBottom:'2px', backgroundColor:'#bb5ced', marginRight:'1px'}} onClick={()=>loadQuizzesByTopicRequest('competitive')}>Competitive Exam</Button>
                </InputGroup>


                <Row>
                    <Col style={{maxWidth:'30vw', maxHeight:'98vh', overflow:'scroll'}}>
                        <ListGroup>
                            <ListGroup.Item>
                                <Form.Control type='text' placeholder='Quiz Title' value={qtitle} onChange={(e) => setQTitle(e.target.value)}> 
                                </Form.Control>

                                {/* <Form.Control as='select' value={quizCategory} onChange={(e) => setQuizCategory(e.target.value)} style={{margin:'5px'}}> 
                                    <option>Select the Quiz Type</option>
                                    <option value="vs">vs</option>
                                    <option value="grammar">Grammar</option>
                                    <option value="comprehension">Comprehension</option>
                                    <option value="competitive">Competitive</option>
                                    </Form.Control> */}

                                <Button onClick={()=>createQuizRequest()}>Create</Button>

                            </ListGroup.Item>

                            {quizlist && quizlist.map((cquiz, index)=>(
                                <ListGroup.Item onClick={()=>selectQuizRequest(cquiz._id)}>
                                    
                                    <p className='h4'>{cquiz.title}</p>

                                </ListGroup.Item>
                            ))}
                        </ListGroup> 

                    </Col>
                    <Col style={{minHeight:'98vh', maxHeight:'99vh', overflow:'scroll'}}>

                        {title && (
                            <>
                            
                                <Card style={{padding:'20px', margin:'20px'}}>

                                    <span>
                                        <span className='h2'>
                                            Quiz : {title}
                                        </span>
                                        <Badge style={{margin:'5px', border:'solid'}} onClick={()=>toggleConcept()}>Concept</Badge>

                                        <Badge style={{margin:'5px', border:'solid'}} onClick={()=>postQuizRequest()}>Post</Badge>
                                        <Badge style={{margin:'5px', border:'solid'}} onClick={()=>unpostQuizRequest()}>UnPost</Badge>
                                        <Badge style={{margin:'5px', border:'solid'}}>Update</Badge>
                                    </span>
                 

                                </Card>


                                {concept && concept != null && viewConcept && (
                                    <ConceptCardEdit concept={concept} quiz_id={quiz_id}/>
                                )}

                                

                                
                                <CreatorInstructionsCard quiz_id={quiz_id} instruction={instruction}/>
                                
                                


                                {/* {passage && passage.title && (
                                    <Card style={{border:'None'}}>
                                        <Card.Header>
                                            <p className='h4'>{passage.title}</p>
                                        </Card.Header>
                                        <Card.Body>
                                            {passage.paras && passage.paras.map((cpara, index)=>(
                                                <p className='h5'>
                                                    {cpara}
                                                </p>
                                            ))}
                                        </Card.Body>
                                    </Card>
                                )} */}

                                <CreatorPassageCard />


                                <CreatorQuestionCard  quiz_id={quiz_id}/>


                                {questions && questions.map((cquestion, index)=>(
                                <>
                                    <QuestionEditCard quiz_id={quiz_id} selectedQuestion={cquestion}/>

                                    <hr />

                                </>
                                ))}


                            
                            
                            </>
                        )}



 
                                
                    
                    </Col>
                </Row>




        </>
    )
}


export default CreateQuizScreen



